import React from 'react'

import BlockBase from '../BlockBase'
import BlockMediaBase from '../BlockMediaBase'
import OurWord from './ourWord'

import OurWord1 from './images/our-words-1.jpg'
import OurWord3 from './images/our-words-3.jpg'

class OurWords extends React.PureComponent {
    render() {
        return (
            <BlockBase id='our-words'>
                <BlockMediaBase image={OurWord1}>
                    <OurWord id='jigar'/>
                </BlockMediaBase>
                <BlockMediaBase image={OurWord3}>
                    <OurWord id='maud'/>
                </BlockMediaBase>
            </BlockBase>
        )
    }
}

export default OurWords