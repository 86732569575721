import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
let ScrollMagic

import Layout from '../components/Layout'
import Hero from '../components/Blocks/Hero'
import HomeIntro from '../components/Blocks/HomeIntro'
import OurWork from '../components/Blocks/OurWork'
import OurCulture from '../components/Blocks/OurCulture'
import OurStats from '../components/Blocks/OurStats'
import OpenPositions from '../components/Blocks/OpenPositions'
import OurWords from '../components/Blocks/OurWords'
import GetInTouch from '../components/Blocks/GetInTouch'
import Instagram from '../components/Blocks/Instagram'
import HomeMenu from '../components/Menu/HomeMenu'
import SEO from '../components/SEO'

class PageFront extends React.PureComponent {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            mainHeight: 0,
            introHeight: 0,
            ourworkHeight: 0,
            ourcultureHeight: 0,
            ourstatsHeight: 0,
            openpositionsHeight: 0,
            ourwordsHeight: 0,
            getintouchHeight: 0
        };
        this.controller = null;
    }

    getHeight(param, e) {
        return this.state[param + 'Height'];
    }

    setHeights() {
        if (typeof $ === undefined) return
        this.setState({
            mainHeight: $('#main > .row > .col-md-9').height() - $('.side-menu').height() - $('#block--get-in-touch').outerHeight(true) - 100,
            introHeight: $('#block--intro').outerHeight(true),
            ourworkHeight: $('#block--our-work').outerHeight(true),
            ourcultureHeight: $('#block--our-culture').outerHeight(true),
            ourstatsHeight: $('#block--our-stats').outerHeight(true),
            openpositionsHeight: $('#block--open-positions').outerHeight(true),
            ourwordsHeight: $('#block--our-words').outerHeight(true),
            getintouchHeight: $('#block--get-in-touch').outerHeight(true)
        });
    }

    componentDidMount() {
        var self = this;
        self._isMounted = true
        $(window).on('load resize', function() {
            if (self._isMounted) {
                self.setHeights();
            }
        });
        $(window).resize();

        ScrollMagic = require('../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic');
        this.controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerHook: 'onLeave',
            triggerElement: '#main',
            duration: 0
        })
            .setClassToggle('.bg-diag', 'sticky')
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerHook: 'onLeave',
            triggerElement: '.side-menu',
            duration: self.getHeight.bind(this,'main'),
            offset: -100
        })
            .setPin('.side-menu')
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: '#block--intro',
            duration: self.getHeight.bind(this,'intro')
        })
            .setClassToggle('.side-menu a[data-target="block--intro"]', 'active')
            .on('enter', function() {
                $('.bg-diag').removeClass('bg-green bg-lightblue');
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: '#block--our-work',
            duration: self.getHeight.bind(this,'ourwork')
        })
            .setClassToggle('.side-menu a[data-target="block--our-work"]', 'active')
            .on('enter', function() {
                $('.bg-diag').removeClass('bg-lightblue');
                $('.bg-diag').addClass('bg-green');
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: '#block--our-culture',
            duration: self.getHeight.bind(this,'ourculture')
        })
            .setClassToggle('.side-menu a[data-target="block--our-culture"]', 'active')
            .on('enter', function() {
                $('.bg-diag').removeClass('bg-green');
                $('.bg-diag').addClass('bg-lightblue');
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: '#block--our-stats',
            duration: self.getHeight.bind(this,'ourstats')
        })
            .setClassToggle('.side-menu a[data-target="block--our-stats"]', 'active')
            .on('enter', function() {
                $('.bg-diag').removeClass('bg-green bg-lightblue');
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: '#block--open-positions',
            duration: self.getHeight.bind(this,'openpositions')
        })
            .setClassToggle('.side-menu a[data-target="block--open-positions"]', 'active')
            .on('enter', function() {
                $('.bg-diag').removeClass('bg-lightblue');
                $('.bg-diag').addClass('bg-green');
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: '#block--our-words',
            duration: self.getHeight.bind(this,'ourwords')
        })
            .setClassToggle('.side-menu a[data-target="block--our-words"]', 'active')
            .on('enter', function() {
                $('.bg-diag').removeClass('bg-green');
                $('.bg-diag').addClass('bg-lightblue');
            })
            .addTo(this.controller);
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { pageContext } = this.props;
        const { lang, jobs } = pageContext;
        const pageTitle = (lang === 'fr') ? 'Portail Carrières' : 'Careers Portal';

        return (
            <Layout locale={lang}>
                <SEO title={pageTitle} />
                <Hero />
                <section id='main' className='container'>
                    <div className="bg-diag"></div>
                    <div className='row'>
                        <div className='col-md-3 position-absolute'>
                            <HomeMenu/>
                        </div>
                        <div className='col-md-9 ml-md-auto'>
                            <HomeIntro />
                            <OurWork />
                            <OurCulture />
                            <OurStats />
                            <OpenPositions positions={jobs} language={lang}/>
                            <OurWords />
                            <GetInTouch />
                        </div>
                    </div>
                </section>

                <Instagram/>
            </Layout>
        )
    }
}

PageFront.propTypes = {
    pageContext: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        jobs: PropTypes.PropTypes.array.isRequired
    })
}

export default PageFront
