import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl";
import TweenMax from 'gsap'
let ScrollMagic

import BlockBase from '../BlockBase'

class OurStatsElement extends React.PureComponent {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            mystat: 0,
            firstrun: true
        };
        this.myRef = React.createRef();
        this.controller = null;
    }

    animNumber() {
        this.state.firstrun = false;
        var self = this;
        var newNumber = this.props.stat;
        this.mydata = {number:0};
        TweenMax.to(this.mydata,3, {number:newNumber, roundProps:"number",
            onUpdate: function() {
                if (self._isMounted) {
                    self.setState({mystat:self.mydata['number']});
                }
            },
            ease:Strong.easeOut});
    }

    componentDidMount() {
        var self = this;
        self._isMounted = true

        ScrollMagic = require('../../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic');
        this.controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerHook: 'onEnter',
            triggerElement: '.stat--count',
            duration: 0,
        })
            .on('enter', function() {
                if(self.state.firstrun === true) {self.animNumber()}
            })
            .addTo(this.controller);
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return <div className='stat col-md-4'>
                <div ref={this.myRef} className='stat--count display-3'>{this.state.mystat}</div>
                <p className='stat--description'>{this.props.children}</p>
            </div>
    }
}

OurStatsElement.propTypes = {
    stat: PropTypes.string.isRequired
}

class OurStats extends React.PureComponent{
    render() {
        return (
        <BlockBase id='our-stats'>
            <div className='row'>
                <OurStatsElement stat='15'>
                    <FormattedMessage id='block--our-stats--languages'/>
                </OurStatsElement>
                <OurStatsElement stat='7'>
                    <FormattedMessage id='block--our-stats--programming-languages'/>
                </OurStatsElement>
                <OurStatsElement stat='11'>
                    <FormattedMessage id='block--our-stats--years'/>
                </OurStatsElement>
                <OurStatsElement stat='14'>
                    <FormattedMessage id='block--our-stats--countries'/>
                </OurStatsElement>
                <OurStatsElement stat='13'>
                    <FormattedMessage id='block--our-stats--ux-meetups'/>
                </OurStatsElement>
                <OurStatsElement stat='46'>
                    <FormattedMessage id='block--our-stats--meetups-organized'/>
                </OurStatsElement>
            </div>
        </BlockBase>
        )
    }
}
export default OurStats


