import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl"

import BlockBase from '../BlockBase'
import Position from '../../Position/Teaser_1'
import LocalizedLink from "../../LocalizedLink";
import routing from '../../../constants/routing'

class OpenPositions extends React.PureComponent {
    render() {
        const { positions, language } = this.props
        return (
            <BlockBase id='open-positions'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h5><FormattedMessage id='block--open-positions--heading'/></h5>
                        <p><FormattedMessage id='block--open-positions--description'/></p>

                        <LocalizedLink to={routing['_open_positions'][language]} className='btn btn-blue'>
                            <FormattedMessage id='block--open-positions--view-all'/>
                        </LocalizedLink>
                    </div>
                    <div className='col-md-6'>
                        { positions.slice(0,3).map((job, index) => (
                            <Position key={`position-teaser-${index}`}
                                      position={job} />
                        ))}
                    </div>
                </div>
            </BlockBase>
        )
    }
}

export default OpenPositions

OpenPositions.propTypes = {
    positions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            blurb_1: PropTypes.string.isRequired,
            application_url: PropTypes.string.isRequired
        })
    ),
    language: PropTypes.string
}