import React from 'react'
import { FormattedMessage } from "react-intl"

import BlockBase from '../BlockBase'
import BlockMediaBase from '../BlockMediaBase'

import IntroImg1 from './images/intro-team-1.jpg'
import IntroImg2 from './images/intro-team-2.jpg'

class OurWork extends React.PureComponent {
    render() {
        return (
            <BlockBase id='our-work'>
                <BlockMediaBase image={IntroImg1} isReversed={true}>
                    <h6><FormattedMessage id='block--our-work--1--title' /></h6>
                    <p><FormattedMessage id='block--our-work--1--paragraph' /></p>
                </BlockMediaBase>

                <BlockMediaBase image={IntroImg2}>
                    <h6><FormattedMessage id='block--our-work--2--title' /></h6>
                    <p><FormattedMessage id='block--our-work--2--paragraph' /></p>
                </BlockMediaBase>
            </BlockBase>
        )
    }
}

export default OurWork