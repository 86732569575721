import React from 'react'
import { FormattedMessage } from "react-intl"

import BlockBase from '../BlockBase'

class HomeIntro extends React.PureComponent {
    render() {
        return (
            <BlockBase id='intro'
                       invisibleTitle={false}>
                <div className='row'>
                    <div className='col-md-6'>
                        <h6><FormattedMessage id='block--intro--left' /></h6>
                    </div>
                    <div className='col-md-6'>
                        <p><FormattedMessage id='block--intro--right' /></p>
                    </div>
                </div>
            </BlockBase>
        )
    }
}

export default HomeIntro
