import React from 'react'
import BlockBase from '../BlockBase'

const data = [
    {
        image: require('./images/149316664_5061191460621066_7360931095495474471_n.jpg'),
        url: 'https://www.instagram.com/p/CLKZOZPlwPa/'
    },
    {
        image: require('./images/92399274_235181431060521_2654762571251309465_n.jpg'),
        url: 'https://www.instagram.com/p/B-xqGbxHmwO/'
    },
    {
        image: require('./images/69699910_963274540682334_7047241637895199562_n.jpg'),
        url: 'https://www.instagram.com/p/B2Mg6IHBUsM/'
    },
    {
        image: require('./images/67479514_124135251911329_8706822914597570435_n.jpg'),
        url: 'https://www.instagram.com/p/B1WsT0hBatu/'
    },
    {
        image: require('./images/61362448_307093836875961_4748430993607504833_n.jpg'),
        url: 'https://www.instagram.com/p/BybHnfcB3dA/'
    },
    {
        image: require('./images/61617800_694744934289636_2771704187793481806_n.jpg'),
        url: 'https://www.instagram.com/p/ByX85cUB3sm/'
    },
    {
        image: require('./images/59376402_589107668245386_5461494668174957622_n.jpg'),
        url: 'https://www.instagram.com/p/Bx2Le7LBNoZ/'
    },
]

const InstagramBlock = () => (
    <BlockBase id='instagram' invisibleTitle={true}>
        {data.map(({image, url}) => (
            <div key={url} id={url} className='instagram--item'>
                <a href={url} target='_blank'>
                    <img src={image} alt="" className='img-fluid'/>
                </a>
            </div>
        ))}
    </BlockBase>
);

export default InstagramBlock

