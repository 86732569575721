import React from 'react'
import PropTypes from 'prop-types'

/**
 * Define base template for Block Media
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const BlockMediaBase = (props) => {
    const wrapperClass = 'media-wrapper ' + (props.isReversed ? 'is-reversed' : '')
    return (
        <div className={wrapperClass}>
            <div className={'media px-0' + (props.isReversed ? ' pl-md-3' : ' pr-md-3')}>
                <img src={props.image} alt="" className='img-fluid'/>
            </div>
            <div className='content'>
                {props.children}
            </div>
        </div>
    )
}

BlockMediaBase.propTypes = {
    image: PropTypes.string.isRequired,
    isReversed: PropTypes.bool
}

BlockMediaBase.defaultProps = {
    isReversed: false
}

export default BlockMediaBase
