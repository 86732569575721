import React, { Component } from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl"
import LocalizedLink from "../LocalizedLink"

class HomeMenu extends React.PureComponent {
    render() {
        return (
            <div>
                <ul className='side-menu'>
                    <li><MenuLink id='side-menu--who-we-are'
                                  anchor='block--intro'/></li>
                    <li><MenuLink id='side-menu--what-we-do'
                                  anchor='block--our-work'/></li>
                    <li><MenuLink id='side-menu--our-culture'
                                  anchor='block--our-culture'/></li>
                    <li><MenuLink id='side-menu--our-team'
                                  anchor='block--our-stats'/></li>
                    <li><MenuLink id='side-menu--work-with-us'
                                  anchor='block--open-positions'/></li>
                    <li><MenuLink id='side-menu--our-words'
                                  anchor='block--our-words'/></li>

                    <li>
                        <LocalizedLink to='_apply_now'>
                            <FormattedMessage id='side-menu--apply'/>
                        </LocalizedLink>
                    </li>
                </ul>
            </div>
        )
    }
}

export default HomeMenu

class MenuLink extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        anchor: PropTypes.string
    }

    _onLinkClicked = (e) => {
        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault()

        var pos = $(`#${this.props.anchor}`).offset().top;
        $('body, html').animate({
            scrollTop: pos - 100
        }, 350)
    }
    render() {
        return (
            <Link onClick={this._onLinkClicked}
                data-target={this.props.anchor}
                to='#'>
                <FormattedMessage id={this.props.id} />
            </Link>
        )
    }
}
