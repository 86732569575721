import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

/**
 * Define the base block template
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const BlockBase = (props) => {
    const blockId = 'block--' + props.id
    const blockClasses = 'block' + ' ' + blockId + ' ' + (props.class ? props.class : '')
    const blockTitleClass = 'block--title' + ' ' + (props.invisibleTitle ? 'is-hidden' : '')
    return (
        <section id={blockId} className={blockClasses}>
            {!props.invisibleTitle ? (
                <h2 className={blockTitleClass}>
                    <FormattedMessage id={'block--' + props.id + '--title'}/>
                </h2>
            ) : (<Fragment/>)}

            <div className='block--body'>{props.children}</div>
        </section>
    )
}

BlockBase.propTypes = {
    id: PropTypes.string.isRequired,
    class: PropTypes.string,
    invisibleTitle: PropTypes.bool
}

BlockBase.defaultProps = {
    invisibleTitle: false
}

export default BlockBase
