import React from 'react'
import LocalizedLink from '../../LocalizedLink'
import { FormattedMessage } from "react-intl"

const Hero = () => (
    <section id='block--hero' className='block--hero jumbotron jumbotron-fluid'>
        <div className='container text-center'>
            <h1 className='display-4'>
                <FormattedMessage id="hero--title" />
            </h1>
            <p className='lead'>
                <LocalizedLink to='_apply_now' className='btn btn-transparent'>
                    <FormattedMessage id="hero--cta-title" />
                </LocalizedLink>
            </p>
        </div>
    </section>
)

export default Hero