import React from 'react'
import { FormattedMessage } from "react-intl"
import LocalizedLink from '../../LocalizedLink'

const GetInTouch = () => (
    <div id='block--get-in-touch' className='block--get-in-touch jumbotron jumbotron-fluid'>
        <div className='container'>
            <h3><FormattedMessage id='block--get-in-touch--text--1'/></h3>
            <h3><FormattedMessage id='block--get-in-touch--text--2'/></h3>

            <LocalizedLink to='_apply_now'>
                <FormattedMessage id='block--get-in-touch--apply'/>
            </LocalizedLink>
        </div>
    </div>
)

export default GetInTouch
