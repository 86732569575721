import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl"

const OurWord = (props) => (
    <div>
        <p><FormattedMessage id={`our-word--${props.id}--text`}/></p>
        <div className="small">
            <FormattedMessage id={`our-word--${props.id}--info`}/>
        </div>
    </div>
)

OurWord.propTypes = {
    id: PropTypes.string.isRequired
}

export default OurWord
