import React from 'react'
import { Link } from 'gatsby'

import LocalizedLink from '../../LocalizedLink'
import BlockBase from '../BlockBase'
import BlockMediaBase from '../BlockMediaBase'

import CultureImg1 from './images/culture-1.jpg'
import CultureImg2 from './images/culture-2.jpg'
import {FormattedMessage} from "react-intl";

class OurCulture extends React.PureComponent {
    render() {
        return (
            <BlockBase id='our-culture'>
                <BlockMediaBase image={CultureImg1} isReversed={true}>
                    <h5><FormattedMessage id='block--our-culture--1--title' /></h5>
                    <p><FormattedMessage id='block--our-culture--1--paragraph-1' /></p>
                    <p><FormattedMessage id='block--our-culture--1--paragraph-2' /></p>
                    <LocalizedLink to='_open_positions' className='link-text'>
                        <FormattedMessage id='block--our-culture--1--link'/>
                    </LocalizedLink>
                </BlockMediaBase>
                <BlockMediaBase image={CultureImg2}>
                    <h5><FormattedMessage id='block--our-culture--2--title' /></h5>
                    <p><FormattedMessage id='block--our-culture--2--paragraph-1' /></p>
                    <LocalizedLink to='_apply_now' className='link-text'>
                        <FormattedMessage id='block--our-culture--2--link'/>
                    </LocalizedLink>
                </BlockMediaBase>
            </BlockBase>
        )
    }
}

export default OurCulture