import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import LocalizedLink from '../LocalizedLink'
import { FormattedMessage } from "react-intl"

const Position = (props) => {
    const { title, path, blurb_1, application_url } = props.position
    return (
        <div className='position--teaser'>
            <h4>{title}</h4>
            <p>{blurb_1}</p>

            <ul className='position--links'>
                <li>
                    <Link to={path}>
                        <FormattedMessage id='position--see-full'/>
                    </Link>
                </li>
                <li>
                    <LocalizedLink to={application_url}>
                        <FormattedMessage id='position--apply'/>
                    </LocalizedLink>
                </li>
            </ul>
        </div>
    )
}

Position.propTypes = {
    position: PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        blurb_1: PropTypes.string.isRequired,
        application_url: PropTypes.string.isRequired
    })
}

export default Position